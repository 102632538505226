import { A } from "@solidjs/router";

export default function Workspaces() {
  return (
    <div class="p-6">
      <h1>Workspaces in static</h1>
      <div>
        Create your own agents and start prompt engineering and some subtitle to
        improve the explanation.
      </div>

      <A href="/workspaces/example">Go to workspace</A>
    </div>
  );
}
